@import 'src/style.scss';

.hero{
    @include center(column);
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: 100vh !important;
    text-align: center;
    .arrow{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        @media only screen and (max-width: 600px) {
           bottom: 10%;
        }
    }
}
.home{
    overflow-y: hidden;
    background: $primary-color;
}
#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.works{
    margin: 5rem 10rem;
    .title-con{
        .line{
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 900px) {
        margin: 5rem 4rem;
    }
    @media only screen and (max-width: 600px) {
        margin: 5rem 2rem;
    }
}
#footer{
    width: 100%;
    background: #E5E5E5;
    color: $primary-color !important;
    position: relative;
    z-index: 99;
    section:first-of-type{
        width: 100%;
        display: flex;
       justify-content: space-between;
       align-items: center;
    }
    .content{
        padding: 2rem 10rem;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 500px) {
            padding: 1rem;
            .social{
                width: 150px;
            }
        }
        .address{
            width: 100%;
            h5{
                color: $primary-color;
                margin-bottom: 1rem;
            }
        }
    }
    .copyright{
        @include center(row);
        width: 100%;
        border-top: 1px solid $primary-color;
        padding: 0.5rem;
        p{
            color: $primary-color;
            opacity: 1;
            text-align: center;
        }
    }

}
.about{
    padding: 2rem 10rem;
    display: flex;
    justify-content: space-between;
    .social{
        p{
            color: #fff;
            opacity: 0.5;
        }
    }
    section:first-of-type{
        width: 50%;
    }
    section:last-of-type{
        width: 30%;
    }
    .span-title{
        height: 100px;
        @include center(row);
        justify-content: flex-start;
        margin:2rem 0;
    }
    h1{
        color: $secondary-color;
    }
    @media only screen and (max-width: 800px) {
        padding: 2rem 3rem;
    }
    @media only screen and (max-width: 600px) {
        height: fit-content !important;
        flex-direction: column;
        section{
            width: 100% !important;
        }
        .span-title:last-of-type{
            height: fit-content;
        }
    }
}
.social{
    display: flex;
    align-items: center;
    img{
        width: 20px;
        height: 20px;
    }
    p{
        color: $primary-color;
        opacity: 1;
        margin-left: 1rem;
    }
    padding: 0.25rem ;
}